import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import { loginReducer } from './loginReducer';
import { productReducer } from './productReducer';
import { orderReducer, orderDetailReducer } from './orderReducer';
import { customerReducer } from './customerReducer';
import { chatReducer, chatDetailReducer } from './chatReducer';

import { notificationReducer } from './notificationReducer';
import { dashboardReducer } from './defaultDashboardReducer';
import { leftMenuReducer } from './leftMenuReducer';
import { roomReducer } from './bookingReducer/roomReducer';
import { requestInfoReducer } from './bookingReducer/requestInfoReducer';
import { defaultDashboardReducer } from './bookingReducer/defaultDashboardReducer';
import { activityStatusReducer } from './activityStatusReducer';
import { reservationDetailReducer, reservationReducer, } from './bookingReducer/rerservationReducer';
import { stampReducer } from './stampReducer';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    loginReducer,
    productReducer,
    orderReducer,
    chatReducer,
    chatDetailReducer,
    customerReducer,
    notificationReducer,
    dashboardReducer,
    orderDetailReducer,
    stampReducer,
    roomReducer,
    reservationDetailReducer,
    reservationReducer,
    requestInfoReducer,
    defaultDashboardReducer,

    leftMenuReducer,
    activityStatusReducer
});

export default reducers;