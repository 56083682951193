import './App.css';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { THEME_CONFIG } from "./configs/AppConfig"
import store from "./utils/redux/store"
import { Provider } from 'react-redux';
import Router from "./router"

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const store = createStore(reducers, composeEnhancers(applyMiddleware()))

function App() {
  return (
    <div className="App">
   
        <Provider store={store}>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
            <Router />
          </ThemeSwitcherProvider>
        </Provider> 
    </div>
  );
}

export default App;
