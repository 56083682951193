
import { apexLineChartDefaultOption, COLOR_2 } from "constants/ChartConstant";
import { FIRM_ID } from "constants/StorageConstant";
import network from "network/network";
import actionTypes from "./actionTypes";
 
 
var customerChartOption = {
    ...apexLineChartDefaultOption,
    ...{
      chart: {
        sparkline: {
          enabled: true,
        }
      },
      colors: [COLOR_2],
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
      }
    }
  }

export function selectMessageChart () {

    var params = {
        firmId : localStorage.getItem(FIRM_ID)
    }
    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_D_MESSAGE_CHART_LOADING,
            isLoading : true
        }) 

        network.get("/admin/DefaultDashboard/selectMessageChartList", params, false).then((result) => {

             
            dispatch({
                type: actionTypes.SELECT_D_MESSAGE_CHART_LOADING,
                isLoading : false
            }) 
             
            if (result.isSuccess) {
                var data = result.data; 
           
 
                dispatch({
                    type: actionTypes.SELECT_D_MESSAGE_CHART_SUCCESS,
                    messageChartDates: data.dateList,
                    messageChartSeries: data.data,
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_D_MESSAGE_CHART_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};


export function selectCustomerChart () {

    var params = {
        firmId : localStorage.getItem(FIRM_ID)
    }
    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_D_CUSTOMER_CHART_LOADING,
            isLoading : true
        }) 

        network.get("/admin/DefaultDashboard/selectCustomerChartList", params, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_D_CUSTOMER_CHART_LOADING,
                isLoading : false
            }) 
             
            if (result.isSuccess) {
 
                var data = result.data;  
 
                dispatch({
                    type: actionTypes.SELECT_D_CUSTOMER_CHART_SUCCESS,
                    customerChartDates: data.dateList,
                    customerChartSeries: data.data,
                    activeCustomer : data.activeCustomer
                }) 

            } else {
                dispatch({
                    type: actionTypes.SELECT_D_CUSTOMER_CHART_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};


export function selectTransactionOrderPercent () {

    var params = {
        firmId : localStorage.getItem(FIRM_ID)
    }

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_D_TRANSACTION_ORDER_PERCENT_LOADING,
            isLoading : true
        }) 

        network.get("/admin/DefaultDashboard/selectTransactionOrderPercent", params, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_D_TRANSACTION_ORDER_PERCENT_LOADING,
                isLoading : false
            }) 
             
            if (result.isSuccess) {
 
                dispatch({
                    type: actionTypes.SELECT_D_TRANSACTION_ORDER_PERCENT_SUCCESS,
                    payload : result.data
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_D_TRANSACTION_ORDER_PERCENT_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};
 


export function selectPrices () {

    var params = {
        firmId : localStorage.getItem(FIRM_ID)
    }
    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_D_PRICES_LOADING,
            isLoading : true
        }) 

        network.get("/admin/DefaultDashboard/selectPrices", params, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_D_PRICES_LOADING,
                isLoading : false
            }) 
             
            if (result.isSuccess) {
 
                dispatch({
                    type: actionTypes.SELECT_D_PRICES_SUCCESS,
                    payload : result.data
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_D_PRICES_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};

export function selectLatestCustomerList () {

    var params = null;
    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_D_LATEST_CUSTOMERS_LOADING,
            isLoading : true
        }) 

        network.get("/admin/DefaultDashboard/selectLatestCustomerList", params, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_D_LATEST_CUSTOMERS_LOADING,
                isLoading : false
            }) 
             
            if (result.isSuccess) {
 
                dispatch({
                    type: actionTypes.SELECT_D_LATEST_CUSTOMERS_SUCCESS,
                    payload : result.data
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_D_LATEST_CUSTOMERS_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};



export function selectLatestOrderList () {

    var params = {
        firmId : localStorage.getItem(FIRM_ID)
    }
    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_D_LATEST_ORDERS_LOADING,
            isLoading : true
        }) 

        network.get("/admin/DefaultDashboard/selectLatestOrderList", params, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_D_LATEST_ORDERS_LOADING,
                isLoading : false
            }) 
             
            if (result.isSuccess) {
 
                dispatch({
                    type: actionTypes.SELECT_D_LATEST_ORDERS_SUCCESS,
                    payload : result.data
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_D_LATEST_ORDERS_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};