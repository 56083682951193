import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon'; 
import { logoutUser } from "utils/redux/actions/loginAction";
import { bindActionCreators } from "redux"; 
import { FIRM_NAME, NAME_SURNAME, USER_IMAGE } from "constants/StorageConstant";
import globalConstant from "constants/globalConstant";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/"
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/"
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/"
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/"
  }
]

export const NavProfile = ({ logoutUserAction }) => { 

  const firmName = localStorage.getItem(FIRM_NAME);
  const nameSurname = localStorage.getItem(NAME_SURNAME) ||"";
  const userImage = localStorage.getItem(USER_IMAGE) || "";

  const logoutClick = () => {
    logoutUserAction();
    setTimeout(() => {
      window.location.reload();
    }, 100);

  } 
  const profilePath = globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.userImage.type, userImage);

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profilePath} />
          <div className="pl-3">
            <h4 className="mb-0">{firmName}</h4>
            <h6 className="mb-0">{nameSurname}</h6>
            {/* <span className="text-muted">Frontend Developer</span> */}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu> 
          {/* <Menu.Item >
            <a href={"/app/pages/profile"}>
              <Icon className="mr-3" type={EditOutlined} />
              <span className="font-weight-normal">{"Profil Bilgileri"}</span>
            </a>
          </Menu.Item> */}
          <Menu.Item key={menuItem.length + 1} onClick={e => logoutClick()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Çıkış Yap</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profilePath} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

// export default connect(null, {signOut})(NavProfile)


function mapStateToProps(state) {

  return {


  }
}

function mapDispatchToProps(dispatch) {
  return {
    logoutUserAction: bindActionCreators(logoutUser, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
