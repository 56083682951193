import { AUTH_TOKEN, FIRM_ID,BRANCH_ID, FIRM_NAME, INITIAL_ROUTE_NAME, LEFT_MENU_LIST, NAME_SURNAME, USER_IMAGE,ISFIRMADMIN } from "constants/StorageConstant";
import network from "network/network";
import { AUTHENTICATED, HIDE_AUTH_MESSAGE, SHOW_LOADING, SIGNIN, SIGNIN_ERROR, SIGNOUT } from "utils/redux/constants/Auth";


export function loginUser (user) {

    return (dispatch, getState) => {

        dispatch({
            type: SHOW_LOADING,
        }) 

        network.get("/admin/account/loginUser", user, false).then((result) => {

            if (result.isSuccess) {

                const loginResult = result.data;
                if (loginResult.isSuccess) {
  
                     
                    var userImage = loginResult.data.userImage || "";
 
                    localStorage.setItem(FIRM_ID, loginResult.data.idToken); 
                    localStorage.setItem(BRANCH_ID, loginResult.data.branchId); 
                    localStorage.setItem(NAME_SURNAME, loginResult.data.nameSurname);
                    localStorage.setItem(FIRM_NAME, loginResult.data.firmName);
                    localStorage.setItem(USER_IMAGE, userImage);
                    localStorage.setItem(AUTH_TOKEN, loginResult.data.token);
                    localStorage.setItem(ISFIRMADMIN, loginResult.data.isFirmAdmin);
                    

                    network.get("/admin/app/selectFirmAppList", null)
                    .then(data => {
                        console.log("Dönen sonuç:", data);
                
                        var menuList = data; 
                        
                        localStorage.setItem("LEFT_MENU_LIST", JSON.stringify(menuList.data));

                        
                    dispatch({
                        type: AUTHENTICATED,
                        token : loginResult.data.token
                    })
                    });

                } else {
               
                    dispatch({
                        type: SIGNIN_ERROR,
                        errorMessage: loginResult.message
                    })
                }

            } else { 
                dispatch({
                    type: SIGNIN_ERROR,
                    errorMessage: result.errorMessage
                })
            }

        });
    }
};


export function authUser (user) {

    return (dispatch, getState) => {

        dispatch({
            type: SHOW_LOADING,
        }) 

        network.get("/admin/account/authUser", user, false).then((result) => {


            if (result.isSuccess) {

                const loginResult = result.data;
                if (loginResult.isSuccess) {
 
               
                    dispatch({
                        type: AUTHENTICATED, 
                    })
                } else {
                    dispatch({
                        type: SIGNIN_ERROR,
                        errorMessage: loginResult.errorMessage
                    })
                }

            } else {
                dispatch({
                    type: SIGNIN_ERROR,
                    errorMessage: result.errorMessage
                })
            }

        });
    }
};


export function hideAuthMessage (user) {

    return (dispatch, getState) => {

        dispatch({
            type: HIDE_AUTH_MESSAGE,
        })
    }
};


export function logoutUser (user) {

    return (dispatch, getState) => {

        localStorage.removeItem("auth_token");
        dispatch({
            type: SIGNOUT,
        })
    }
};
