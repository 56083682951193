 
import { APP_PREFIX_PATH } from 'configs/AppConfig'

 

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}apps/booking/dashboards`,
  title: 'sidenav.dashboard',
  icon: "DashboardOutlined",
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/apps/booking/dashboards/default`,
      title: 'sidenav.dashboard.default',
      icon: "DashboardOutlined",
      breadcrumb: false,
      submenu: []
    }, 
  ]
}]

const appsNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'sidenav.apps',
  icon: "AppstoreOutlined",
  breadcrumb: false,
  // submenu: [ 
  //   {
  //     key: 'apps-chat',
  //     path: `${APP_PREFIX_PATH}/apps/chat`,
  //     title: 'sidenav.apps.chat',
  //     icon: WhatsAppOutlined,
  //     breadcrumb: false,
  //     submenu: []
  //   },
  //   {
  //     key: 'apps-ecommerce-productList',
  //     path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
  //     title: 'sidenav.apps.ecommerce.productList',
  //     icon: UnorderedListOutlined,
  //     breadcrumb: true,
  //     submenu: []
  //   }, 
  //   {
  //     key: 'apps-ecommerce-orders',
  //     path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
  //     title: 'sidenav.apps.ecommerce.orders',
  //     icon: ShoppingCartOutlined,
  //     breadcrumb: true,
  //     submenu: []
  //   },
  //   {
  //     key: 'apps-ecommerce-customers',
  //     path: `${APP_PREFIX_PATH}/apps/ecommerce/customers`,
  //     title: 'sidenav.apps.ecommerce.customers',
  //     icon: OrderedListOutlined,
  //     breadcrumb: true,
  //     submenu: []
  //   }, 
  // ],
  submenu: [ 
    {
      key: 'apps-chat',
      path: `${APP_PREFIX_PATH}/apps/chat`,
      title: 'sidenav.apps.chat',
      icon: "WhatsAppOutlined",
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'apps-reservation-roomlist',
      path: `${APP_PREFIX_PATH}/apps/booking/room-list`,
      title: 'sidenav.apps.reservation.roomlist',
      icon: "UnorderedListOutlined",
      breadcrumb: true,
      submenu: []
    }, 
    {
      key: 'apps-reservation-reservationlist',
      path: `${APP_PREFIX_PATH}/apps/booking/reservations`,
      title: 'sidenav.apps.reservation.reservationlist',
      icon: "ShoppingCartOutlined",
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'apps-ecommerce-customers',
      path: `${APP_PREFIX_PATH}/apps/booking/customers`,
      title: 'sidenav.apps.ecommerce.customers',
      icon: "OrderedListOutlined",
      breadcrumb: true,
      submenu: []
    }, 
    {
      key: 'apps.reservation.requestınfolist',
      path: `${APP_PREFIX_PATH}/apps/booking/request-info-list`,
      title: 'sidenav.apps.reservation.requestınfolist',
      icon: "OrderedListOutlined",
      breadcrumb: false,
      submenu: []
    },
  ]
}];

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree, 
]

export default navigationConfig;


// export const bookingNavigationConfig = [
//   ...dashBoardNavTree,
//   ...appsNavTree, 
// ];


