import React, { useEffect } from 'react';
import { Button, Form, Input, Alert, Card, Row, Col } from "antd";

import PropTypes from 'prop-types';
import { hideAuthMessage, loginUser } from 'utils/redux/actions/loginAction';
import { INITIAL_ROUTE_NAME } from 'constants/StorageConstant';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { motion } from "framer-motion"

export const LoginForm = props => {

	const navigate = useNavigate();
	const dispatch = useDispatch()

	const initialCredential = {
		userName: '',
		password: ''
	}
	const {
		otherSignIn,
		showForgetPassword,
		hideAuthMessageAction,
		showLoading,
		signInWithGoogle,
		signInWithFacebook,
		extra,
		loginUserAction

	} = props

	const loading = useSelector(state => state.loginReducer.loading)
	const isSuccess = useSelector(state => state.loginReducer.isSuccess)
	const errorMessage = useSelector(state => state.loginReducer.errorMessage)
	const showMessage = useSelector(state => state.loginReducer.showMessage)
	const token = useSelector(state => state.loginReducer.token)
	useEffect(() => {
		if (token) {
			navigate("/");
		}
		if (showMessage) {
			setTimeout(() => {
				dispatch(hideAuthMessage());
			}, 3000);
		}
	}, []);

	useEffect(() => {
		if (isSuccess == true) {
			setTimeout(() => {
				navigate("/");
			}, 150);
		}

	}, [isSuccess]);

	const onLogin = values => {
		dispatch(hideAuthMessage())
		dispatch(loginUser(values));
	};


	const backgroundStyle = {
		backgroundImage: 'url(/img/others/img-17.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	}
	return (
		<div className='auth-container'>
			<div className="h-100" style={backgroundStyle}>
				<div className="container d-flex flex-column justify-content-center h-100">
					<Row justify="center">
						<Col xs={20} sm={20} md={20} lg={7}>
							<Card>
								<div className="my-4">
									<div className="text-center">
										<img className="img-fluid" src={`/img/${'logo.png'}`} alt="" />
										<p> </p>
									</div>
									<Row justify="center">
										<Col xs={24} sm={24} md={20} lg={20}>
											<motion.div
												initial={{ opacity: 0, marginBottom: 0 }}
												animate={{
													opacity: showMessage ? 1 : 0,
													marginBottom: showMessage ? 20 : 0
												}}>
												<Alert type="error" showIcon message={(errorMessage)}></Alert>
											</motion.div>
											<Form
												layout="vertical"
												name="login-form"
												initialValues={initialCredential}
												onFinish={onLogin}
											>
												<Form.Item
													name="userName"
													label="Email"
													rules={[
														{
															required: true,
															message: 'Lütfen Email adresinizi giriniz',
														},
														{
															type: 'email',
															message: 'Lütfen geçerli bir Email adresi giriniz!'
														}
													]}>
													<Input prefix className="text-primary" />
												</Form.Item>
												<Form.Item
													name="password"
													label={
														<div className='d-flex justify-content-between w-100 align-items-center'>
															<span>Şifre</span>

														</div>
													}
													rules={[
														{
															required: true,
															message: 'Lütfen şifrenizi giriniz',
														}
													]}
												>
													<Input.Password prefix className="text-primary" />
												</Form.Item>

												<Form.Item>
													<Button type="primary" htmlType="submit" block loading={loading} >
														Giriş Yap
													</Button>
												</Form.Item>

											</Form>
										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</div>

	)
}

LoginForm.propTypes = {
	otherSignIn: PropTypes.bool,
	showForgetPassword: PropTypes.bool,
	extra: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
};

LoginForm.defaultProps = {
	otherSignIn: true,
	showForgetPassword: false
};

export default LoginForm;

