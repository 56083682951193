import network from "network/network";
import actionTypes from "./actionTypes";
 
 

export function selectCustomerList (user) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_CUSTOMER_LIST_LOADING,
            isLoading : true
        }) 

        network.get("/admin/Client/selectClientList", user, false).then((result) => {

            
            dispatch({
                type: actionTypes.SELECT_CUSTOMER_LIST_LOADING,
                isLoading : false
            }) 
             
            const {data} = result;
         
            if (result.isSuccess && data.isSuccess) {
            
                var customers = JSON.parse(data.data);
                dispatch({
                    type: actionTypes.SELECT_CUSTOMER_LIST_SUCCESS,
                    payload : customers
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_CUSTOMER_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};
 
export function selectFirmSellerList (user) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_FIRM_SELLER_LIST_LOADING,
            isLoading : true
        }) 

        network.get("/admin/FirmSellers/selectFirmSellerList", user, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_FIRM_SELLER_LIST_LOADING,
                isLoading : false
            }) 
             
            const{data} = result; 
            if (result.isSuccess && data.isSuccess) {
 
                dispatch({
                    type: actionTypes.SELECT_FIRM_SELLER_LIST_SUCCESS,
                    payload : data.data
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_FIRM_SELLER_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};
 